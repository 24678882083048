* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
}

#root {
  background-color: #fafafa;
  height: 100vh;
}

#left-nav-bar .MuiButtonBase-root:hover,
#left-nav-bar .Mui-selected {
  background-color: #f4f4f5;
}

#left-nav-bar .Mui-selected .MuiListItemIcon-root {
  color: #d97706;
}

#left-nav-bar .MuiListItemIcon-root {
  min-width: 40px;
  color: #a1a1aa;
}

#left-nav-bar .Mui-selected .MuiListItemText-root {
  color: #d97706;
  margin-bottom: 0;
}

#left-nav-bar .MuiListItemText-root {
  color: #3f3f46;
  margin-bottom: 0;
}

.content-title {
  font-weight: 700 !important;
  font-size: 1.5rem !important;
  line-height: 1.375 !important;
  margin-bottom: 16px !important;
}

table .MuiSelect-select,
table .MuiSelect-iconStandard {
  display: none;
}

table .MuiTableHead-root {
  background-color: #f4f4f5;
}

table .MuiTableHead-root .MuiTableCell-root {
  font-weight: 700;
}

table tbody .MuiTableRow-root:hover {
  background-color: #fafafa;
}

.box-shadow {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
    rgba(9, 9, 11, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}
